<script>

import _debounce from 'lodash.debounce';
import JsonViewer from 'vue-json-viewer'
import Drawer from "vue-simple-drawer";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import EmptyList from "@/components/widgets/empty_list"
import Integrations  from "@/components/projects/integrations";

import {
    userMethods,
	projectMethods,
    propertyMethods,
    integrationMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		JsonViewer,
        Drawer,
        Multiselect,
        EmptyList,
        Integrations
	},
	data() {
		return {
			submitted:false,
            submittedValue:false,
			items:[],
            currentPage:1,
			perPage:10,
            rows:0,
            query:'',
            newItemsModal : false,
            apikey:'****************',
            itemDetailModal:false,
            itemImagesModal:false,
            selectedItem:{},
            useOnlyActives:true,
            openRightDrawer:false,
            propertyModal:false,
            properties:[],
            property: {
                values:[]
            },
            property_types: [
                {
                    id: 'text',
                    name: this.$t("properties.property_types_text")
                },
                {
                    id: 'list',
                    name: this.$t("properties.property_types_list")
                },
                {
                    id: 'number',
                    name: this.$t("properties.property_types_number")
                },
                {
                    id: 'date',
                    name: this.$t("properties.property_types_date")
                },
                {
                    id: 'boolean',
                    name: this.$t("properties.property_types_boolean")
                },
            ],
            propertyValue:{},
            empty_list_config:{
                "title" : this.$t("items.empty_search_title"),
                "linkButtonText" : this.$t("common.reset_search"),
                icon: "fa-cubes"
            },
            empty_config:{
                "title" : this.$t("items.empty_title"),
                "subtitle" : this.$t("items.empty_text"),
                "buttonText" : this.$t("integrations.new"),
                icon: "fa-cubes"
            },
            empty_property_config:{
                "title" : this.$t("properties.empty_title"),
                "subtitle" : this.$t("properties.empty_subtitle"),
                "buttonText" : this.$t("properties.new"),
                icon: "fa-cubes"
            },
			showIntegrationsAvailable: false,
            integrations:[],
            isLoading:true
		};
	},
    validations: {
        property: {
            name: { required },
            type: { required },
            column_name: { required },
        },
        propertyValue:{
            id: { required },
            value: { required },
        }
    },
	created(){
        this.debounceItems = _debounce(this.searchItems.bind(this), 1000);
    },  
    methods: {
		...projectMethods,
        ...userMethods,
        ...propertyMethods,
        ...integrationMethods,

        loadIntegrations(){
			let loader = this.$loading.show();
			const params={
				q: `where[project]=${localStorage.getItem('current_project')}&where[group]=feed`,
			}
			this.getIntegrations(params).then((integrations)=>{
				if(integrations&& integrations.data){
					this.integrations= integrations.data;
                    this.empty_config.buttonText = this.integrations.length > 0 ? '' : this.empty_config.buttonText; 
                    this.empty_config.subtitle = this.integrations.length > 0 ? this.empty_config.subtitle  : this.$t('items.empty_text_no_integration'); 
                }
            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t('integrations.get_integrations_error') ,title:  this.$t('integrations.title') });
            }).finally(()=>{
                loader.hide();
            });
		},

        rightDrawer() {
            this.openRightDrawer = !this.openRightDrawer;
        },

        onPropertiesClicked(){
            this.openRightDrawer = true;
            this.loadProperties();
        },
        onUseOnlyActivesChanged(){
            this.query = ''
            this.currentPage = 1;
            this.loadItems();
        },

        onItemImagesClicked(item){
            this.selectedItem = item.data;
            this.itemImagesModal = true;
        },

        onNewItemClicked(){
            this.newItemsModal = true;
        },  

        onDetailItemClicked(item){
            this.selectedItem = item.data;
            this.itemDetailModal = true;
        },
        loadProperties(){
            let loader = this.$loading.show();

            const params = {
                q: `project=${localStorage.getItem("current_project")}&entity=product`
            }
            this.getProperties(params).then((properties)=>{
                this.properties = properties;
                if(this.properties && this.properties.length > 0){
                    this.properties.forEach(p => {
                        p.type = this.property_types.filter(t=> t.id == p.type).length > 0 ? this.property_types.filter(t=> t.id == p.type)[0] : p.type;
                    });
                }
            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t("properties.get_properties_error"),title:  this.$t("properties.title") });
            }).finally(()=>{
                loader.hide();
            })
        },

        searchItems(query){
      
            this.isLoading = true;
            let loader = this.$loading.show();
            this.query = query;
            this.empty_list_config.subtitle =  this.$t("items.empty_search_text").replaceAll('#', this.query);
            if(query && query != ''){
                
                this.items=[];
                this.rows = 0;
                const params ={
					q: this.useOnlyActives ? `whereObj={"project":"${this.project._id}", "data.active":1, "$or": [{"data.name" : {"$regex" : ".*${query}." , "$options": "i"} }, {"data.sku" : {"$regex" : ".*${query}." , "$options": "i"}} ]}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` : `whereObj={"project":"${this.project._id}", "$or": [{"data.name" : {"$regex" : ".*${query}." , "$options": "i"} }, {"data.sku" : {"$regex" : ".*${query}." , "$options": "i"}} ]}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
				}

                this.getItems(params).then((items)=>{
                if(items&& items.data){
                    this.items= items.data;
                    this.rows= items.totalCount
                }
                this.isLoading = false;
                loader.hide();
                });
            }else{
                this.isLoading = false;
                this.query = '';
                this.currentPage = 1;
                loader.hide();
                this.loadItems();
            }
        },

        onItemsListPageClicked() {
            if(this.query && this.query !=''){
                this.searchItems(this.query);
            }else{
                this.loadItems();    
            }
			
		},

        copyValue(value){
            if(value=='project'){
                this.$notify({ type: 'success', text: this.$t('items.add_project_copied'),title:  this.$t('items.title') });
                navigator.clipboard.writeText(this.project._id);
            }
            else{

                let loader = this.$loading.show();

                const currentUser = JSON.parse(localStorage.getItem("auth.currentUser"));
                
                this.getUser(currentUser.user._id).then((user)=>{
                    this.$notify({ type: 'success', text: this.$t('items.add_apikey_copied'),title:  this.$t('items.title') });
                    navigator.clipboard.writeText(user.data.apiKey);
                }).catch(()=>{
                    this.$notify({ type: 'error', text: this.$t('items.add_apikey_error'),title:  this.$t('items.title') });
                }).finally(()=>{
                    loader.hide();
                })
            }
        },

        loadItems(){
            if(this.project?._id){
                this.isLoading = true;
				let loader = this.$loading.show();
				const params = {
					q: this.useOnlyActives ? `whereObj={"project":"${this.project._id}", "data.active":1}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` : `whereObj={"project":"${this.project._id}"}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
				}

                this.getItems(params).then((res)=>{
					this.items = res.data;
                    this.rows= res.totalCount;
                    
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('items.list_get_error'),title:  this.$t('items.title') });
				}).finally(()=>{
					loader.hide();
                    this.isLoading = false;
				})
			}
		},

        onIntegrationClicked(){
            window.open("https://docs.goshops.com/api-reference/items")
        },
        onNewPropertyClicked(){
            this.propertyModal = true;
            this.property = {
                values:[]
            };
        },
        onConfirmPropertyClicked(){
            this.submitted = true;
			this.$v.$touch();
            
            if (this.$v.property.$invalid) {
				return false;
			}

            let loader = this.$loading.show();
            if(this.property._id){
                this.property.type = this.property.type.id;
                this.updateProperty(this.property).then(()=>{
                    this.$notify({ type: 'success', text: this.$t('properties.update_property_success'),title:  this.$t('properties.title') });
                    this.property = {
                        values:[]
                    };
                    this.propertyModal = false;
                    this.loadProperties();
                }).catch(()=>{
                    this.$notify({ type: 'error', text: this.$t('properties.update_property_error'),title:  this.$t('properties.title') });
                }).finally(()=>{
                    loader.hide();
                })
            }else{
                this.property.source = "go_shops";
                this.property.entity = "product";
                this.property.project = localStorage.getItem("current_project");
                this.property.type = this.property.type.id;

                this.createProperty(this.property).then(()=>{
                    this.$notify({ type: 'success', text: this.$t('properties.create_property_success'),title:  this.$t('properties.title') });
                    this.property = {
                        values:[]
                    };
                    this.propertyModal = false;
                    this.loadProperties();
                }).catch(()=>{
                    this.$notify({ type: 'error', text: this.$t('properties.create_property_error'),title:  this.$t('properties.title') });
                }).finally(()=>{
                    loader.hide();
                })
            }
        },
        onCancelPropertyClicked(){
            this.propertyModal = false;
            this.property = {
                values:[]
            };
        },
        onAddValueToSet(){
            this.submittedValue = true;
			this.$v.$touch();
            
            if (this.$v.propertyValue.$invalid) {
				return false;
			}

            this.property.values.push(this.propertyValue);
            this.submittedValue = false;
            this.propertyValue = {}
        },
        onRemoveValue(value){
            this.property.values = this.property.values.filter(p=> p.id != value.id);
        },
        onEditProperty(property){
            this.property = property;
            this.property.type = this.property_types.filter(t=>t.id == property.type).length > 0 ? this.property_types.filter(t=>t.id == property.type) : property.type;
            this.propertyModal = true;
        },
        onRemovePropertyClicked(property){
            let loader = this.$loading.show();

            this.deleteProperty(property._id).then(()=>{
                this.$notify({ type: 'success', text: this.$t('properties.delete_property_success'),title:  this.$t('properties.title') });
                this.loadProperties();
            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t('properties.delete_property_error'),title:  this.$t('properties.title') });
            }).finally(()=>{
                loader.hide();
            })
        },
        onResetSearchClicked(){
            this.query=''
            this.loadItems();
        },
        onAddIntegrationClicked(){
            this.showIntegrationsAvailable = true
        },
        onConfirmNewIntegration(){
            this.showIntegrationsAvailable = false;
            this.query=''
        },
},
	mounted() {
		this.loadIntegrations();
	},
	props: {
		project : {
			type: Object
		}
	},
    watch: {
        project() {
			if (this.project && this.project._id) {
				this.loadItems();
			}
		}
    },
	emits: []
};
</script>

<template>
	<div class="row">
        <div class="col-12">
            <div class="card mb-0">
				<div class="card-body">
                <div class="row mb-0">
                    <div class="col-sm-4">
                        <div class="search-box me-2 mb-0 d-inline-block">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('common.search')"
                                    @input="debounceItems($event.target.value)"
                                    v-model="query"
                                />
                                <i class="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-2 mt-2" >
                        <b-form-checkbox
                            v-model="useOnlyActives"
                            class="mb-0"
                            checked
                            @change="onUseOnlyActivesChanged"
                            plain>
                            {{$t('items.list_only_actives')}}
                        </b-form-checkbox>
                    </div>

                    <div class="col-sm-6">
                        <div class="text-sm-end">
                            <button
                                type="button"
                                class="btn btn-primary mb-0 me-0"
                                @click="onPropertiesClicked">
                            <i class="mdi mdi-cog-outline me-1"></i> {{ $t("properties.manage")}}
                        </button>
                            <!--<button
                                type="button"
                                class="btn btn-success btn-rounded mb-2 me-2"
                                @click="onNewItemClicked">
                            <i class="mdi mdi-plus me-1"></i> {{$t('items.add_items')}}
                        </button>-->
                        </div>
                    </div>
                </div>
                <EmptyList :config="empty_list_config" v-if="!isLoading && items.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if=" !isLoading && items.length == 0 && query ==''" @onButtonClicked="onAddIntegrationClicked" class="mt-3 mb-3"/>
            </div>
            <b-modal id="integration-modal" size="xl" v-model="showIntegrationsAvailable" :title="$t('integrations.new')" hide-footer>
                <Integrations @confirmAddIntegration="onConfirmNewIntegration" :integrationsAdded="integrations" :group="'feed'" />
          </b-modal>
        </div>
            <Drawer
                @close="rightDrawer"
                :align="'right'"
                :closeable="true"
                :maskClosable="true"
                :zIndex="1002">
                    <div v-if="openRightDrawer">
                        <div class="offcanvas-header">
                            <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                            <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                            {{ $t("properties.title")}}
                            </h4>
                        </div>
                        <hr class="mt-1" />
                        <div class="offcanvas-body">
                            <div class="row">
                                <div class="search-box col-sm-8">
                                    <div class="position-relative">
										
									</div>
                                </div>
                                <div class="col-sm-4 mx-auto">
                                    <button
											type="button"
											class="btn btn-sm btn-success btn-rounded mb-2"
											@click="onNewPropertyClicked">
											<i class="mdi mdi-plus me-1"></i> {{ $t("properties.new")}}
									</button>
                                </div>
                            </div>
                            <EmptyList :config="empty_property_config" v-if="properties.length == 0" @onButtonClicked="onNewPropertyClicked" class="mt-3 mb-3"/>
                            
                            <table class="table table-sm align-middle table-nowrap table-hover mt-1 pe-2 ps-2" v-if="properties.length > 0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">{{ $t("properties.name")}}</th>
                                        <th scope="col">{{ $t("properties.type")}}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="property in properties" :key="property._id">
                                        <td>
                                            {{property.name}}
                                        </td>
                                        <td>
                                            {{property.type?.name}} {{ property.type?.id == 'list' ? `(${property.values.length})` : '' }}
                                        </td>
                                        <td style="float: right;">
                                            <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditProperty(property)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                </Drawer>
            <div class="row ">
                <div class="table-responsive" v-if="items.length>0">
                    <table  id="my-table" class="table align-middle table-nowrap table-hover" style="background-color: white;">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Sku</th>
                                    <th scope="col">{{$t('items.list_item_name')}}</th>
                                    <th scope="col">{{$t('items.list_item_price')}}</th>
                                    <th scope="col">{{$t('items.list_item_images')}}</th>
                                    <th scope="col">{{$t('items.list_item_status')}}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" :key="item._id">
                                    <td>{{item.data.id}}</td>
                                    <td>{{item.data.sku}}</td>
                                    <td>{{item.data.name}}</td>
                                    <td>$ {{item.data.price}}</td>
                                    <td><i class= 'bx bx-show interact' v-on:click="onItemImagesClicked(item)"/></td>
                                    <td>
                                        <span class="badge font-size-10"
                                            :class="{
                                            'bg-success': `${item.data.active}` === true || `${item.data.active}` == 1,
                                            'bg-warning': `${item.data.active}` === false || `${item.data.active}` == 0,
                                        }">{{ item.data.active || item.data.active == 1 ? 'Activo' : 'No activo' }}</span>
                                    </td>
                                    <td>
                                        <i class= 'bx bx-detail interact' v-on:click="onDetailItemClicked(item)"/>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div>
                <div class="mb-3" v-if="rows > perPage">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded mb-0">
                                <b-pagination
                                    @input="onItemsListPageClicked"
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage">
                                </b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="itemImagesModal" id="modal-center" hide-footer :title="selectedItem?.name" title-class="font-18">
            <b-carousel style="text-shadow: 0px 0px 2px #000" controls indicators fade>
              <b-carousel-slide :img-src="img.url" v-for="img in selectedItem.imgs" :key="img.url"></b-carousel-slide>
            </b-carousel>
        </b-modal>
        
        <b-modal  v-model="itemDetailModal" id="modal-center" hide-footer :title="selectedItem?.name" title-class="font-18">
            <json-viewer :value="selectedItem"></json-viewer>
        </b-modal>

        <b-modal v-model="newItemsModal" id="modal-center" hide-footer :title="$t('items.add_items')" title-class="font-18">

            <b-tabs nav-class="nav-tabs-custom">
                <b-tab active>
                    <template v-slot:title>
                        <span class="d-none d-sm-inline-block"> API </span>
                    </template>
                    
                    <div class="row mt-4" >
                        <p class="text-muted"> {{$t('items.add_items_api_label')}} 
                            <br>
                            <a v-on:click="onIntegrationClicked()" class="mt-0 mt-2 interact"> <i class= 'bx bx-code'></i> API Reference</a>
                        </p>
                        

                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label>x-api-key:</label>
                                    <div class="d-flex">
                                        <input
                                            v-model="apikey"
                                            type="password"
                                            class="form-control"
                                            disabled
                                        />
                                        <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('apikey')" style="align-self: center; margin-left: 5px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col">
                                <div class="mb-3">
                                    <label>{{$t('items.add_project')}}:</label>
                                    <div class="d-flex">
                                        <input
                                        id="projectid"
                                        v-model="project._id"
                                        type="text"
                                        class="form-control"
                                        disabled
                                        />
                                        <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('project')" style="align-self: center; margin-left: 5px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>

                <b-tab>
                    <template v-slot:title>
                        <span class="d-none d-sm-inline-block"> CSV (Soon)</span>
                    </template>
                    <div class="row mt-4" >
                        <p class="text-muted"> {{$t('items.add_items_csv_label')}}</p>
                    </div>
                </b-tab>

                <b-tab>
                    <template v-slot:title>
                        <span class="d-none d-sm-inline-block"> Manual (Soon)</span>
                    </template>
                    <div class="row mt-4" >
                        <p class="text-muted"> {{$t('items.add_items_manual_label')}}</p>
                    </div>
                </b-tab>
            </b-tabs>
		</b-modal>

        <b-modal  v-model="propertyModal" id="modal-center" hide-footer :title="property._id ? property.name : $t('properties.new')" title-class="font-18">
            <div class="row">
                <div class="mb-3">
                    <label for="title">{{ $t("properties.name")}}*</label>
                    <input
                        id="title"
                        v-model="property.name"
                        type="text"
                        class="form-control"
                        :placeholder="$t('properties.name_placeholder')"
                        :class="{ 'is-invalid': submitted && $v.property.name.$error }"
                    />
                    <div v-if="submitted && !$v.property.name.required" class="invalid-feedback">
                        {{$t("properties.name_required")}}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="title">{{ $t("properties.column_name")}}*</label>
                    <input
                        id="title"
                        v-model="property.column_name"
                        type="text"
                        class="form-control"
                        :placeholder="$t('properties.column_name_placeholder')"
                        :class="{ 'is-invalid': submitted && $v.property.column_name.$error }"
                        :disabled="property._id !=null"
                    />
                    <div v-if="submitted && !$v.property.column_name.required" class="invalid-feedback">
                        {{$t("properties.column_name_required")}}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="title">{{$t("properties.type")}}*</label>
                    <multiselect 
                        v-model="property.type"
                        :options="property_types"
                        :selectLabel="$t('common.select')"
                        :deselectLabel="$t('common.deselect')"
                        :placeholder="$t('properties.type_placeholder')"
                        :multiple="false"
                        :class="{'is-invalid': submitted && $v.property.type.$error,}"
                        track-by="id" 
                        label="name"
                        :showNoResults="false">
                    </multiselect>
                    <div v-if="submitted && !$v.property.type.required" class="invalid-feedback">
                        {{$t("properties.type_required")}}
                    </div>
                </div>
                <div class="mb-3" v-if="property.type?.id=='list'">
                    <label for="title">{{$t("properties.values")}}</label>
                    <div class="row">
                        <div class="col">
                            <b-form-input size="sm" :placeholder="$t('properties.value_id_placeholder')" v-model="propertyValue.id" :class="{'is-invalid': submittedValue && $v.propertyValue.id.$error,}"></b-form-input>
                            <div v-if="submittedValue && !$v.propertyValue.id.required" class="invalid-feedback">
                                {{$t("properties.value_id_required")}}
                            </div>    
                        </div>
                        <div class="col">
                            <b-form-input size="sm" :placeholder="$t('properties.value_name_placeholder')" v-model="propertyValue.value" :class="{'is-invalid': submittedValue && $v.propertyValue.value.$error,}"></b-form-input>
                            <div v-if="submittedValue && !$v.propertyValue.value.required" class="invalid-feedback">
                                {{$t("properties.value_name_required")}}
                            </div>    
                        </div>
                        <div class="col">
                            <button class="btn btn-sm btn-primary" @click="onAddValueToSet">{{$t("common.add")}}</button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div>
                            <ul class="list-style">
                                <li v-for="value in property.values" :key="value.id"> {{`${value.id} - ${value.value}`}} <i v-on:click="onRemoveValue(value)" class="bx bx-trash text-danger interact"/></li>
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>
            <div class="text-end pt-5 mt-3">
                <b-button variant="light" @click="onCancelPropertyClicked">{{$t('common.cancel')}}</b-button>
                <b-button variant="primary" class="ms-1" @click="onConfirmPropertyClicked">{{$t('common.confirm')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<style>
.interact{
    cursor: pointer;
}

.vue-simple-drawer{ 
    min-width: 500px !important;
}

#integration-modal .modal-body {
  background-color: var(--bs-body-bg);
}
</style>


